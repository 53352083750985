/**
 *
 * Loading
 *
 */
import React from 'react';
import styled from 'styled-components/macro';
import { Dimmer, Loader } from 'semantic-ui-react';

interface Props {}

export function Loading(props: Props) {
  return (
    <Dimmer active={true}>
      <Loader inline="centered">Carregando</Loader>
    </Dimmer>
  );
}

interface ImageProps {
  src: string;
  alt: string;
}

const Image = styled.img<ImageProps>`
  width: 500px;
  height: 500px;
`;
