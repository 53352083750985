import { User } from 'models/user/User';
import { ResponseData } from 'models/services/ResponseData';
import UserRepository from 'repositories/UserRepository';
import { endpoint } from 'services/endpoint';
import { Http } from 'services/Http';

export const getUser = (): ResponseData<User> => {
  if (UserRepository.data) {
    return UserRepository.data;
  }
  const corporacao = process.env.REACT_APP_CORPORACAO || '';
  const http = new Http();
  throw http
    .post<User>(endpoint.user, { Chave: corporacao })
    .then(user => {
      UserRepository.setDataCache({ data: user as User });
    })
    .catch(error => {
      UserRepository.setDataCache({ data: undefined, error: error });
    });
};

export const getToken = (): ResponseData<User> => {
  return UserRepository.data ?? {};
};
