export const endpoint = {
  plans: '/v1/ecommerce/plans',
  texts: '/v1/ecommerce/texts',
  plansByClientId: clientId => `/v1/ecommerce/plans/${clientId}`,
  theme: '/v1/ecommerce/theme',
  address: `/v1/ecommerce/address`,
  kinship: `/v1/ecommerce/kinship`,
  user: `/v1/auth/login-corporacao`,
  contract: `/v1/ecommerce/finish`,
  esitefFinish: `/v1/ecommerce/esitefFinish`,
  cardflag: `/v1/ecommerce/cardflag`,
  planoTabelapreco: `/v1/ecommerce/plano-tabelapreco`,
  termOfUse: `/v1/estatico/term-of-use`,
};
