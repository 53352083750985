export class Http {
  private static instance;
  baseConfig: object | undefined;
  baseURL: string | undefined;
  defaultHeaders: object | undefined;

  constructor(config?) {
    if (Http.instance == null) {
      this.baseURL = process.env.REACT_APP_API_BASE_URL || '';
      this.baseConfig = config || {};
      this.defaultHeaders = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };

      Http.instance = this;
    }

    return Http.instance;
  }

  get<T>(endpoint, config?) {
    let _config = {
      method: 'GET',
      ...this.baseConfig,
      ...config,
      mode: 'cors',
    };

    _config.headers = {
      ...this.defaultHeaders,
      ...this.getHeaderAuthorization(),
      ..._config.headers,
    };

    return fetch(`${this.baseURL}${endpoint}`, _config).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<T>;
    });
  }

  patch<T>(endpoint, payload, config?) {
    return fetch(`${this.baseURL}${endpoint}`, {
      method: 'PATCH',
      body: JSON.stringify(payload),
      ...this.baseConfig,
      ...config,
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<T>;
    });
  }

  put<T>(endpoint, payload, config?) {
    return fetch(`${this.baseURL}${endpoint}`, {
      method: 'PUT',
      body: JSON.stringify(payload),
      ...this.baseConfig,
      ...config,
    }).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<T>;
    });
  }

  post<T>(endpoint, payload, config?) {
    let _config = {
      method: 'POST',
      body: JSON.stringify(payload),
      ...this.baseConfig,
      ...config,
    };

    _config.headers = {
      ...this.defaultHeaders,
      ...this.getHeaderAuthorization(),
      ..._config.headers,
    };
    return fetch(`${this.baseURL}${endpoint}`, _config).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<T>;
    });
  }

  getQuery<T>(endpoint, config?, query?) {
    let _config = {
      method: 'GET',
      ...this.baseConfig,
      ...config,
      mode: 'cors',
    };

    _config.headers = {
      ...this.defaultHeaders,
      ...this.getHeaderAuthorization(),
      ..._config.headers,
    };

    return fetch(
      `${this.baseURL}${endpoint}${objectToQuerystring(query || {})}`,
      _config,
    ).then(response => {
      if (!response.ok) {
        throw new Error(response.statusText);
      }
      return response.json() as Promise<T>;
    });
  }

  getHeaderAuthorization() {
    return {};
  }
}

function objectToQuerystring(obj) {
  return Object.keys(obj).reduce(function (str, key, i) {
    var delimiter, val;
    delimiter = i === 0 ? '?' : '&';
    key = encodeURIComponent(key);
    val = encodeURIComponent(obj[key]);
    return [str, delimiter, key, '=', val].join('');
  }, '');
}
