import { del, get, set } from 'idb-keyval';
import { ResponseData } from 'models/services/ResponseData';

export class ResponseDataRepository<T> {
  key: string;
  data!: ResponseData<T>;

  constructor(key) {
    this.key = key;
  }

  private getDataCache = async (): Promise<ResponseData<T> | undefined> => {
    try {
      const data = await get<ResponseData<T>>(this.key);
      if (data) {
        return data;
      } else {
        return undefined;
      }
    } catch (error) {
      console.error(error);
      return { data: (undefined as unknown) as T, error };
    }
  };

  setDataCache = async (data: ResponseData<T>): Promise<void> => {
    try {
      await set(this.key, data);
      this.data = data;
    } catch (error) {
      console.error(error);
      this.data = { data: (undefined as unknown) as T, error };
    }
  };

  deleteDataCache = async (): Promise<void> => {
    try {
      await del(this.key);
      this.data = { data: (undefined as unknown) as T };
    } catch (error) {
      console.error(error);
    }
  };
}
